import React from 'react'
import Header from '@/components/Layout/Header'
import Footer from '@/components/Layout/Footer'
import Hero from '@/components/Layout/Hero'

export interface MainLayoutProps {
  /** Page content */
  children: React.ReactNode
  nav?: any
  hero?: any
  title?: string
}

/** Component shares layout structure between pages. Pass common sections like header, footer and content container here and wrap page components with it */
const LayoutHome = ({ children, nav, hero, title }: MainLayoutProps) => (
  <>
    <Header nav={nav} />
    <main id="main" className="w-full main-height container mx-auto">
      <Hero overlay title={hero?.title || title} button={hero?.button} image={hero?.image} />
      {children}
    </main>
    <Footer />
  </>
)

export default LayoutHome

import React from 'react'
import ButtonLink from '@/components/ButtonLink'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const FeaturedItems = ({ items }: any) => {
  const bigImage = items[0]

  const smallerImages = [items[1], items[2]]

  return (
    <div className="container mx-auto p-4 my-12 lg:my-24">
      <Big background={bigImage?.background} url={bigImage?.url} label={bigImage?.label} content={bigImage?.content} />
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mt-8">
        {smallerImages.map((item, idx: number) => (
          <Small key={idx} background={item?.background} url={item?.url} label={item?.label} content={item?.content} />
        ))}
      </div>
    </div>
  )
}

const Big = ({ background, content, url }: any) => {
  const bg = background && getImage(background?.localFile)
  const to = url ? JSON.parse(url)?.url : false
  return (
    <div className="w-full h-0 pb-[100%] lg:pb-[50%] bg-white relative">
      <div className="w-full z-40 absolute bg-primary/40 h-full">
        <p className="p-16 absolute lg:pr-44 lg:-translate-y-1/2 lg:top-1/2 text-3xl md:text-5xl xl:text-8xl text-white leading-7 xl:leading-[5rem]">
          {content}
        </p>

        {to && (
          <div className="h-full flex content-center lg:content-end justify-center lg:justify-end w-full items-end lg:pr-24 pb-12">
            <ButtonLink secondary to={to} label={'LUE LISÄÄ'} />
          </div>
        )}
      </div>
      {bg && (
        <GatsbyImage className="top-0 w-full h-full absolute object-cover pb-[100%] lg:pb-[50%]" image={bg} alt="" />
      )}
    </div>
  )
}

const Small = ({ background, content, url }: any) => {
  const bg = background && getImage(background?.localFile)
  const to = url ? JSON.parse(url)?.url : false
  return (
    <div className="w-full h-0 pb-[100%] bg-white relative">
      <div className="w-full z-40 absolute bg-primary/40 h-full">
        <p className="p-16 absolute xl:pr-44 xl:-translate-y-1/2 xl:top-1/2 text-3xl md:text-4xl xl:text-6xl 2xl:text-8xl text-white leading-7 2xl:leading-[5rem]">
          {content}
        </p>
        {to && (
          <div className="h-full flex content-center xl:content-end justify-center w-full items-end pb-12">
            <ButtonLink secondary to={to} label={'LUE LISÄÄ'} />
          </div>
        )}
      </div>
      {bg && <GatsbyImage className="top-0 w-full h-full absolute object-cover pb-[100%]" image={bg} alt="" />}
    </div>
  )
}

export default FeaturedItems

import React from 'react'
import { graphql } from 'gatsby'
import Layout from '@/components/Layout/Layout/Home'
import Seo from '@/components/Seo'
import { Seo as SeoType } from '@/types.d'
import LinkChoice from '@/components/LinkChoice'
import FeaturedItems from '@/components/FeaturedItems'
import { parseLinkBlank } from '@/utils'

interface HomeData {
  data: {
    page: {
      title: string
      content: string
      uri: string
      featuredHeroImage?: any
      homeHeroTitle?: string
      heroLink?: string
      heroLinkText?: string
      linkChoiceTitle?: string
      linkChoices?: any
      seo: SeoType
      featuredImage: any
      blocks: any
      blocksJSON: any
      featuredItems: any
    }
    menu?: any
  }
  pageContext: {
    postlang: string
  }
}

const HomePageTemplate = ({ data }: HomeData) => {
  // Page data
  const { page } = data

  const heroContent = {
    title: page?.homeHeroTitle,
    image: page?.featuredImage,
    button: {
      link: page?.heroLink && JSON.parse(page.heroLink).url,
      linkText: page?.heroLinkText,
      blank: parseLinkBlank(page?.heroLink ? JSON.parse(page.heroLink).blank : false),
    },
  }

  const { menu } = data

  return (
    <Layout title={page?.title} hero={heroContent} nav={menu}>
      {page.seo && (
        <Seo
          title={page?.seo?.title}
          description={page?.seo?.metaDesc}
          keywords={page?.seo?.metaKeyword}
          pathname={page?.uri}
          image={page?.featuredImage?.node?.localFile?.url}
          twitterTitle={page?.seo?.twitterTitle}
          twitterDescription={page?.seo?.twitterDescription}
          opengraphTitle={page?.seo?.opengraphTitle}
          opengraphDescription={page?.seo?.opengraphDescription}
          opengraphImage={page?.seo?.opengraphImage?.localFile?.url}
          twitterImage={page?.seo?.twitterImage?.localFile?.url}
        />
      )}
      <LinkChoice title={page?.linkChoiceTitle} links={page?.linkChoices} />

      <FeaturedItems items={page?.featuredItems} />
    </Layout>
  )
}

export const HomePageQuery = graphql`
  query HomePageById($id: String!) {
    page: wpPage(id: { eq: $id }) {
      id
      content

      title
      uri
      heroLinkText
      heroLink
      homeHeroTitle
      featuredItems {
        background {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        label
        url
        content
      }

      ...featuredHeroImage
      ...seo
      ...linkChoices
      blocks {
        name
        attributesJSON
        ... on WpCoreImageBlock {
          mediaItem {
            node {
              id
              localFile {
                id
                childImageSharp {
                  gatsbyImageData(height: 640)
                }
              }
            }
          }
        }
        innerBlocks {
          ... on WpCoreImageBlock {
            mediaItem {
              node {
                id
                localFile {
                  id
                  childImageSharp {
                    gatsbyImageData(height: 640)
                  }
                }
              }
            }
          }
        }
      }
    }
    menu: wpMenu(slug: { eq: "main-menu" }) {
      ...navigation
    }
  }
`

export default HomePageTemplate

import React from 'react'
import ButtonLink from '@/components/ButtonLink'
import WheelSymbol from '@/components/Layout/Nav/assets/symbol-wheel.png'
import { parseLinkBlank } from '@/utils'

interface LinkChoice {
  link_choice: string
  link_choice_label: string
  blank?: boolean
}
export interface LinkChoiceProps {
  title: string
  links: LinkChoice[]
}

const LinkChoice = ({ title, links }: LinkChoiceProps) => {
  return (
    <section className="my-14">
      <h2 className="text-5xl lg:text-8xl text-center my-8 lg:my-0 lg:text-left">{title}</h2>
      <div className="flex justify-center gap-8 md:gap-0 lg:justify-between flex-wrap max-w-7xl mx-auto">
        {links?.map((link: LinkChoice) => {
          const to = link && JSON.parse(link.link_choice)?.url
          // const blank = link?.link_choice ? JSON.parse(link.link_choice).blank : false
          const blank = parseLinkBlank(link?.link_choice ? JSON.parse(link.link_choice).blank : false)
          return <Choice key={link.link_choice_label} to={to} label={link.link_choice_label} blank={blank} />
        })}
      </div>
    </section>
  )
}
LinkChoice.defaultProps = {
  title: 'Aloita valitsemalla',
}
export default LinkChoice

const Choice = ({ label, to, blank }: { label: string; to: string; blank: boolean }) => (
  <>
    {to && (
      <div className="flex gap-6 items-center md:my-4">
        <img
          width="100"
          height="100"
          className="w-12 md:w-24 hover:rotate-12 transform duration-500 ease-in-out"
          src={WheelSymbol}
          alt=""
        />
        <ButtonLink
          className="w-64 md:w-96 px-12 text-center lg:text-4xl 2xl:text-5xl"
          to={to}
          label={label}
          blank={blank}
        />
      </div>
    )}
  </>
)

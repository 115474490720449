import { useState, useEffect } from 'react'
/**
 * All Helper functions are listed here
 */

/**
 *
 * @param content any content to format
 * @param number number of characters
 * @returns
 */
export const excerpt = (content: any = '', number = 350, words = false) => {
  if (content) {
    const regex = /(<([^>]+)>)/gi
    const format = content?.replace(regex, '')
    const formatSpaces = format?.replace(/(\r\n|\n|\r)/gm, '').trim()
    const cutContent = words ? formatSpaces.split(' ').slice(0, number).join(' ') : formatSpaces.slice(0, number)

    const addDots = formatSpaces.length > cutContent.length ? '...' : ''
    const final = `${cutContent}${addDots}`

    return final
  } else {
    return ''
  }
}

/**
 *
 * @param string Content
 * @param number Number of words to show
 * @returns
 */
export const trimWords = (string: any = '', number = 1) => {
  const trimmedString = string?.split(' ').slice(0, number).join(' ')

  return trimmedString
}

// function for fixing gatby build error on type window
export const isBrowser = () => typeof window !== 'undefined'

// New function for fixing gatsby build error and validatoion error....
export function useWindowSize() {
  const [windowSize, setWindowSize] = useState(0)
  function handleResize() {
    setWindowSize(window.innerWidth)
  }
  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', handleResize)
      handleResize()
      return () => window.removeEventListener('resize', handleResize)
    }
  }, [])
  return windowSize
}
/**
 * Function for getting archive url
 * @param lang
 * @param data
 * @returns
 */
export const getArhiveUrl = (lang: string, data: any) => {
  const url =
    lang === 'fi'
      ? data.archiveFi.split(',')[1]
      : lang === 'en'
      ? data.archiveEn.split(',')[1]
      : lang === 'sv'
      ? data.archiveSv
      : data.archiveFi.split(',')[1]

  return url === ',,,' ? '/' : url
}
/**
 * Function for removing query strings
 * @returns
 */
export const clearQuerySrings = () => {
  if (typeof window !== 'undefined') {
    window.history.pushState(
      { path: window.location.protocol + '//' + window.location.host + window.location.pathname },
      '',
      window.location.protocol + '//' + window.location.host + window.location.pathname
    )
  }
}

export function parseLinkBlank(blank: string | boolean | number): boolean {
  let parsed
  if (typeof blank === 'string' && blank == '1') {
    parsed = Number(blank)
  } else if (blank == 'true') {
    parsed = blank = true
  } else {
    return false
  }
  return !!parsed
}

/**
 * Function for queryStrings
 * @returns
 */
export const getQuerySring = () => {
  if (typeof location !== 'undefined') {
    const params = new URLSearchParams(location.search)
    const s = params?.get('s')

    return s
  } else {
    return ''
  }
}
